import React, { useState, memo } from 'react';
import { addDays } from 'utils/date';
import hash from 'object-hash';

import { saveCookie, getCookie } from 'utils/cookie';

import { Banner } from './Banner';

const URGENCY_BANNER_DAYS_TO_SHOW_AGAIN = 5; // days
const URGENCY_BANNER_COOKIE = 'wtr_cookie_urgency_banner';

export interface UrgencyBannerProps {
  html?: string;
  type: 'info' | 'warning' | 'danger';
}

const UrgencyBanner = ({ html = '', type }: UrgencyBannerProps) => {
  const hashValue = hash(html + type);
  const cookieValue = getCookie(URGENCY_BANNER_COOKIE);
  const [show, setShow] = useState(!(cookieValue === hashValue));

  const close = () => {
    setShow(false);
    saveCookie(
      URGENCY_BANNER_COOKIE,
      hashValue,
      addDays(new Date(), URGENCY_BANNER_DAYS_TO_SHOW_AGAIN),
    );
  };

  if (!show) return null;

  return (
    <Banner type={type} onClose={close}>
      {{ __html: html }}
    </Banner>
  );
};

UrgencyBanner.displayName = 'UrgencyBanner';

export default memo(UrgencyBanner);
